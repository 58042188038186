<template>
  <Layout>
    <div class="row" v-if="program">
      <div class="col-lg-12">
        <div class="card">
          <div>
            <div v-if="userType === 'admin'">
              <b-button v-if="program.is_approved" class="ml-auto mt-3"
                style="margin-right: 18px; height: 28px; padding-top: 3px" type="primary"
                @click="toggleProgramStatus(program._id, program.is_disabled)">
                {{
      program.is_disabled
        ? `${changingStatus ? "Activating..." : "Activate"}`
        : `${changingStatus ? "Deactivating..." : "Deactivate"}`
    }}</b-button>

              <b-button v-if="!program.is_approved" class="ml-auto mt-3"
                style="margin-right: 18px; height: 28px; padding-top: 3px" type="primary" @click="approveProgram"><i
                  class="ri-settings-5-line mr-2"></i> Approve</b-button>
              <b-button v-else class="ml-auto mt-3" style="margin-right: 18px; height: 28px; padding-top: 3px"
                type="primary" @click="editProgram"><i class="ri-settings-5-line mr-2"></i> Edit</b-button>
            </div>
            <div v-else>
              <b-button class="ml-auto mt-3" style="margin-right: 18px; height: 28px; padding-top: 3px" type="primary"
                @click="editProgram"><i class="ri-settings-5-line mr-2"></i> Edit</b-button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5 mt-1">
                <div class="m-1">
                  <img :src="program.banner" alt="Banner" class="program-banner" srcset="" />
                </div>
              </div>
              <div class="col-xl-7">
                <div class="mt-4 mt-xl-3">
                  <h5 class="mt-1 mb-3">{{ program.name }}</h5>

                  <p class="mt-3">
                    {{ program.description }}
                  </p>
                  <hr class="my-4" />

                  <div class="row">
                    <div class="col-md-6">
                      <div>
                        <h5 class="font-size-14">Specification</h5>
                        <ul class="list-unstyled product-desc-list">
                          <li>
                            <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                            Minimum {{ program.more_details.min_people }} people
                          </li>
                          <li>
                            <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                            Maximum {{ program.more_details.max_people }} people
                          </li>
                          <li>
                            <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                            Price ( {{ minPrice }} USD - {{ maxPrice }} USD)
                          </li>
                          <!-- <li>
                            <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                            Maximum {{ program.more_details.max_people }} people
                          </li> -->
<!-- 
                          <li>
                            <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                            Cost
                            <strong>{{ program.starting_from }}</strong> USD
                          </li> -->
                          <li>
                            <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                            Program guide
                            <strong v-if="program.program_guide &&
      program.program_guide.name
      ">{{ program.program_guide.name }}</strong>
                            <strong v-else>No guide found</strong>
                          </li>
                          <li v-if="program.no_of_days">
                            <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                            Number Of Days {{program.no_of_days}}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>

          <div class="m-4">
            <div class="product-desc">
              <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
                <b-tab title="More">
                  <table>
                    <tr>
                      <td style="width: 138px">Cancellation Policy</td>
                      <td>
                        {{ program.cancellation_policy }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 138px">Included</td>
                      <td>
                        {{ program.more_details.included }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 138px">Excluded</td>
                      <td>
                        {{ program.more_details.excluded }}
                      </td>
                    </tr>
                  </table>
                </b-tab>
                <b-tab title="Details">
                  <div class="row">
                    <div class="col-12" v-for="details in program.details" :key="details._id">
                      <div class="card m-1 p-4">
                        <div class="d-flex flex-wrap">
                          <img style="width: 84px" :src="details.image" alt="Image" srcset="" class="mr-4" />
                          <div class="mt-3">
                            <h6>{{ details.title }}</h6>
                            <p>{{ details.description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Media">
                  <div class="row">
                    <div class="col-md-3 col-xl-3">
                      <div class="card m-1 p-4 card-upload-div">
                        <FormulateForm name="imageupload">
                          <FormulateInput type="image" name="images" label="Add Images"
                            validation="mime:image/jpeg,image/png,image/gif" :uploader="uploadCoverImage" />
                        </FormulateForm>
                      </div>
                    </div>
                    <div class="col-md-3 col-xl-3" v-for="(media, index) in mediaList" :key="index">
                      <div class="card tribe-details-main-img" :style="`background:url(${media.media.large})`">
                        <div class="card-body" style="background-color: rgba(0, 0, 0, 0.5)">
                          <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white"
                            style="margin-right: -22px; margin-top: -8px">
                            <template v-slot:button-content>
                              <i class="ri-settings-5-line"></i>
                            </template>
                            <b-dropdown-item @click="editImage(media)">Edit</b-dropdown-item>
                            <b-dropdown-item @click="deleteImage(media)">
                              Delete</b-dropdown-item>
                          </b-dropdown>
                          <div class="tribe-text">
                            <div class="tribe-title">
                              {{ media.meta ? media.meta.credits : "" }}
                            </div>
                            <div class="tribe-subtitle">
                              {{ media.meta ? media.meta.description : "" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
      <b-modal id="edit-image" v-model="modalShow" @hide="closeModal" :hide-footer="true">
        <FormulateForm name="otherForm" @submit="handleSubmit">
          <FormulateInput type="text" name="credits" v-model="credits"
            :value="selectedImage ? selectedImage.meta.credits : ''" label="Credits" />
          <FormulateInput type="textarea" name="description" v-model="description" validation="max:150,length"
            :value="selectedImage ? selectedImage.meta.description : ''" label="Descripition" />
          <div class="text-center float-right">
            <FormulateInput type="submit" :disabled="isLoading" :label="isLoading ? 'Updating...' : 'Update'" />
          </div>
        </FormulateForm>
      </b-modal>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { program } from "@/config/api/programs";
import { programMethods } from "@/state/helpers";
import capitalizeFirstLetter from "@/utils/capitalize.js";
import { tribe } from "@/config/api/tribes";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      modalShow: false,
      program: null,
      timeslots: {},
      updatingSlots: false,
      changingStatus: false,
      mediaList: [],
      selectedImage: null,
      credits: "",
      description: "",
      isLoading: false,
      timeSlotSchema: [
        {
          type: "group",
          repeatable: true,
          class: "available-time-slot",
          name: "slots",
          addLabel: "+ Add more",
          children: [
            {
              type: "date",
              name: "date",
              validation: "required",
            },
          ],
        },
      ],
    };
  },
  watch: {
    program(val) {
      this.timeslots.slots = [];
      if (!val) return [];

      val.available_dates.forEach((ele) => {
        this.timeslots.slots.push({
          date: ele,
        });
      });
    },
  },
  components: {
    Layout,
  },
  computed: {
    id() {
      return this.$route.query.id;
    },

    userType() {
      return this.$store.getters["auth/userType"];
    },
    minPrice() {
      if (this.program) {
        const temp = this.program.packages.map((item) => item.total_price);
        return Math.min(...temp);
      }
      return 0;
    },
    maxPrice() {
      if (this.program) {
        const temp = this.program.packages.map((item) => item.total_price);
        return Math.max(...temp);
      }
      return 0;
    },
  },
  created() {
    this.getGuideList();
    this.getProgramDetails();
    this.getProgramMedia();
  },
  methods: {
    getGuideList() {
      const api = program.getGuideList;
      this.generateAPI(api)
        .then((res) => {
          console.log("dgdsfgad", res);
          this.guideList = res.data.data.map((item) => {
            return {
              value: item._id,
              label: item.name,
            };
          });
        })
        .finally(() => {
          this.guideList.push({ value: "", label: "No Guide" });
          console.log(this.guideList);
        });
    },
    editImage(data) {
      (this.selectedImage = data), (this.modalShow = true);
    },
    closeModal() {
      (this.selectedImage = null), (this.credits = ""), (this.description = "");
    },
    handleSubmit() {
      this.isLoading = false;
      if (this.credits.length == 0 && this.description.length == 0) {
        return;
      }
      try {
        this.isLoading = true;
        let api = {
          url: `v1/admin/program_media/${this.selectedImage._id}`,
          method: "POST",
          data: {
            meta: {
              credits: this.credits,
              description: this.description,
            },
          },
        };
        this.generateAPI(api).then(() => {
          console.log("Update");
          this.isLoading = false;
          this.getProgramMedia();
          this.modalShow = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
          });

          Toast.fire({
            icon: "success",
            title: "Updated successfully",
          });
        });
      } catch (e) {
        console.log("not update", e);
        this.isLoading = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
        });

        Toast.fire({
          icon: "success",
          title: "Not updated",
        });
      }
    },

    async getProgramMedia() {
      const programAPI = program.getProgramMedia;
      programAPI.id = this.id;
      this.generateAPI(programAPI).then((mediaList) => {
        this.mediaList = mediaList.data;
      });
    },

    async uploadCoverImage(img, progress) {
      const form = new FormData();
      form.append("file", img);
      form.append("fileType", img.type.split("/")[1]);
      form.append("name", img.name);
      const api = tribe.uploadMultimedia;
      api.data = form;
      const result = await this.fileUploadAPI(api, progress).then((res) => {
        return res.data;
      });

      const programAPI = program.addProgramMedia;
      programAPI.data = {
        meta: {
          credits: "",
          description: "",
        },
        type: "image",
        program: this.id,
        media: {
          large: result.data,
          medium: result.data,
          small: result.data,
          original: result.data,
        },
      };
      await this.generateAPI(programAPI);
      this.$formulate.setValues("imageupload", { images: null });
      this.getProgramMedia();
      return;
    },
    async reload() {
      await this.getProgramDetails();
    },
    getProgramDetails() {
      const api = program.getSingleProgram;
      api.params = {
        id: this.id,
      };
      console.log("calling API");
      this.generateAPI(api).then((res) => {
        this.program = res.data.program;
      });
    },
    async submitHandler(data) {
      const api = program.updateProgramDates;
      api.data = {
        programId: this.$route.query.id,
        type: "admin",
        available_dates: data.slots.map((item) => {
          return item.date;
        }),
      };
      this.updatingSlots = true;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Program date slot updated successfully.", {
            title: "Program update",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err.data);
          this.$bvToast.toast("Program slot update failed!.", {
            title: "Program update",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.updatingSlots = false;
        });
    },
    editProgram() {
      this.$router.push({ name: "editProgram" });
      this.selectProgram(this.program);
    },
    approveProgram() {
      this.$router.push({ name: "approveProgram" });
      this.selectProgram(this.program);
    },
    ...programMethods,
    toggleProgramStatus(id, is_disabled) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to ${is_disabled ? "enable" : "disable"}?`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((result) => {
          if (result) {
            const data = {
              type: capitalizeFirstLetter(this.userType),
              programId: id,
              is_disabled: !is_disabled,
            };
            const api = program.disableProgram;
            api.data = data;
            this.changingStatus = true;
            this.generateAPI(api)
              .then((res) => {
                console.log(res.data);
                this.program.is_disabled = !this.program.is_disabled;
                this.$bvToast.toast("Program status updated.", {
                  title: "Program status",
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.$bvToast.toast("Program status update failed!.", {
                  title: "Program status",
                  variant: "danger",
                  solid: true,
                });
              })
              .finally(() => {
                this.changingStatus = false;
              });
          }
        })
        .catch((err) => {
          console.log(err);
          // An error occurred
        });
    },
    deleteImage(item) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete this ${item.type}.`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            let api = {
              url: `v1/admin/program_media/${item._id}`,
              method: "DELETE",
            };
            this.generateAPI(api).then(() => {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
              });

              Toast.fire({
                icon: "success",
                title: "Deleted successfully",
              });
              this.getProgramMedia();

              console.log("deleted");
            });
          }
        })
        .catch(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
          });

          Toast.fire({
            icon: "error",
            title: "Not deleted ",
          });
          console.log("Image delete confirm error");
        });
    },
  },
};
</script>

<style scoped>
.program-banner {
  width: 100%;
  max-width: 380px;
  display: block;
  margin: auto;
  border-radius: 12px;
}
</style>
<style>
.available-time-slot .formulate-input-grouping {
  display: flex;
  flex-wrap: wrap;
}

.available-time-slot .formulate-input-group-repeatable {
  border-bottom: unset !important;
}

.available-time-slot .formulate-input-group-repeatable-remove {
  top: 26px !important;
}

.formulate-input-upload-area {
  height: 228px !important;
}

.tribe-title {
  color: #fa7da9;
  font-weight: 900;
  font-size: 2rem;
  padding-top: 8px;
}

.tribe-text {
  margin-top: 158px;
}

.tribe-subtitle {
  color: #fff;
  font-size: 1rem;
}

.tribe-details-main-img {
  height: 20rem;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

@media screen and (max-width: 480px) {
  .tribe-details-main-img {
    height: 168px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .tribe-title {
    color: #fa7da9;
    font-weight: 900;
    font-size: 1rem;
    padding-top: 8px;
  }

  .tribe-subtitle {
    color: #fff;
    font-size: 0.6rem;
  }

  .tribe-text {
    margin-top: 70px;
  }
}

@media screen and (max-width: 1700px) {
  .tribe-details-main-img {
    height: 188px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .card-upload-div {
    width: 100%;
    height: 184px;
    object-fit: contain;
  }

  .tribe-title {
    color: #fa7da9;
    font-weight: 900;
    font-size: 1rem;
    padding-top: 8px;
  }

  .tribe-subtitle {
    color: #fff;
    font-size: 0.6rem;
  }

  .tribe-text {
    margin-top: 60px;
  }

  .formulate-input-upload-area {
    height: 108px !important;
  }
}
</style>
